<template>
  <v-container class="d-flex justify-center" fluid>
    <v-row class="mt-n13" no-gutters>
      <v-col cols="12">
        <v-card class="moholand-shadow rounded-lg">
          <v-card-title>
            علاقه مندی های من
          </v-card-title>

          <v-tabs
            dark
            background-color="white"
            show-arrows
            class="d-flex justify-center"
          >
            <v-tab class="pa-0 ma-0">
              <v-btn
                class="ma-2"
                elevation="0"
                rounded
                :style="selectedCategory !== 0 && 'color: #808080'"
                :color="selectedCategory === 0 ? '#FB6D00' : '#D0D0D0'"
                :dark="selectedCategory === 0"
                @click="selectCategory(0)"
              >
                فایل ها
              </v-btn>
            </v-tab>
            <v-tab class="pa-0 ma-0">
              <v-btn
                class="ma-2"
                elevation="0"
                rounded
                :style="selectedCategory !== 1 && 'color: #808080'"
                :color="selectedCategory === 1 ? '#FB6D00' : '#D0D0D0'"
                :dark="selectedCategory === 1"
                @click="selectCategory(1)"
              >
                افراد دنبال شده
              </v-btn>
            </v-tab>
          </v-tabs>

          <!-- <div class="d-flex flex-wrap pa-4">
            <search-bar @onSubmit="onSearchSubmit" />
          </div> -->

          <MyDataTable
            :headers="headers"
            :items="items"
            :loading="loading"
            :pagesLength="pagesLength"
            @onPageChanged="GetList"
            @onDeleteClick="deleteItem"
            :isEditEnabled="false"
            outlined
          >
            <template #middleButtonTag="{ row }">
              <v-btn
                v-if="selectedCategory === 0"
                class="mx-2"
                dark
                small
                color="primary"
                @click="onShowClick(row)"
              >
                <v-icon small dark>mdi-pen</v-icon>
                مشاهده
              </v-btn>
              <v-btn
                v-else-if="
                  selectedCategory === 1 && row.user && row.user.seller
                "
                class="mx-2"
                dark
                small
                color="primary"
                :to="'/sellers/' + row.user.id"
              >
                <v-icon small dark>mdi-pen</v-icon>
                فروشگاه
              </v-btn>
            </template>
            <template #contentTag="{ row }">
              <td>
                {{
                  row.product
                    ? row.product.name
                    : row.user && row.user.seller
                    ? row.user.seller.store_name
                    : "-"
                }}
              </td>
            </template>
          </MyDataTable>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MyDataTable from "@/components/MyDataTable";

export default {
  mounted() {
    this.GetList();
  },
  components: {
    MyDataTable,
  },
  methods: {
    GetList() {
      if (!this.loading) this.loading = true;
      this.items = [];

      if (this.selectedCategory == 0)
        MyAxios.get("/" + this.$route.meta.api + "/index", {
          params: {
            with: "product",
            page: this.$route.query?.page || 1,
            conditions: {
              user_id: this.$store.state.userData.id || undefined,
            },
          },
        })
          .then((response) => {
            console.log(response.data);
            this.loading = false;
            this.pagesLength = response.data?.last_page || 1;
            this.path = response.data?.path || "";
            this.items = response.data?.data || [];
          })
          .catch((error) => {
            this.loading = false;
            console.log("items", error.response);
          });
      else if (this.selectedCategory == 1)
        MyAxios.get("followers/index", {
          params: {
            with: ["user", "user.seller"],
            page: this.$route.query?.page || 1,
            conditions: {
              follower_id: this.$store.state.userData.id || undefined,
            },
          },
        })
          .then((response) => {
            console.log(response.data);
            this.loading = false;
            this.pagesLength = response.data.last_page;
            this.path = response.data.path;
            this.items = response.data.data;
          })
          .catch((error) => {
            this.loading = false;
            console.log("items", error.response);
          });
    },
    onEditClick(item) {
      this.$router.push({
        path: this.destinationRoute + "edit/" + item.id,
      });
    },
    onShowClick(item) {
      window.open("/products/" + item.product?.slug, "_blank");
    },
    onDeleteClick(item) {
      this.$refs.itemsDialog.show(
        "ایا مایل به حذف '" + item.name + "' هستید؟",
        item
      );
    },
    deleteItem(item) {
      if (!this.loading) this.loading = true;
      if (this.selectedCategory == 0)
        MyAxios.post(`/${this.$route.meta.api}/delete`, {
          user_id: item.user_id,
          product_id: item.product_id,
        })
          .then((response) => {
            this.$root.$emit("toast", {
              text: item.product.name + "با موفقیت حذف شد ",
              type: "success",
            });
            this.GetList();
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "مشکلی پیش آمد. لطفا دوباره تلاش کنید.",
            });
          });
      else if (this.selectedCategory == 1 && item.user?.seller?.id)
        MyAxios.get("followers/unFollow/" + item.user?.id)
          .then((response) => {
            this.$root.$emit("toast", {
              text: item.user?.seller.store_name + "با موفقیت حذف شد ",
              type: "success",
            });
            this.GetList();
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "مشکلی پیش آمد. لطفا دوباره تلاش کنید.",
            });
          });
    },
    selectCategory(category) {
      console.log(category, this.selectedCategory);
      this.selectedCategory = category;
      this.GetList();
      if (category == 0)
        this.headers = [
          {
            text: "فایل",
            align: "start",
            value: "product.name",
          },
          { text: "عملیات", value: "actions", sortable: false },
        ];
      else
        this.headers = [
          {
            text: "نام فروشگاه",
            align: "start",
            value: "title",
            groupable: false,
            sortable: false,
          },
          {
            text: "عملیات",
            value: "actions",
            groupable: false,
            sortable: false,
          },
        ];
      this.$router.push({ name: "WishList" });
    },
    updateItems(items) {
      this.items = items;
    },
  },
  data() {
    return {
      destinationRoute: this.$route.fullPath.slice(
        0,
        this.$route.fullPath.indexOf("index")
      ),
      loading: true,
      selectedCategory: 0,
      items: [],
      page: 1,
      path: null,
      pathParams: {},
      pagesLength: 1,
      headers: [
        {
          text: "فایل",
          align: "start",
          value: "product.name",
        },
        { text: "عملیات", value: "actions", sortable: false },
      ],
    };
  },
};
</script>

<style></style>
